module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styletron/gatsby-browser.js'),
      options: {"plugins":[],"prefix":"_","debug":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"andrew-xia-profile","short_name":"andrew-xia","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"/Users/andrewxia/Documents/work/nat/natmegy/src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a25bfb3c3dc67faf7d5987b245452783"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
